import commaNumber from 'comma-number';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Alert from 'react-bootstrap/Alert';
import { FaSpinner, FaCaretDown, FaCaretUp } from "react-icons/fa";
import { VscWarning } from "react-icons/vsc";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { VscChromeClose } from "react-icons/vsc";
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import api from '../api';

const formatNumber = commaNumber.bindWith(',', '.');

const DashboardFormCard = ({form, providedSessionData, handleSessionCountFromForm, displayForms, handleSaveFavForm, handleRemoveFavForm, isFavForm, showingOnFavFormsCard}) => {
  const [formSessionData, setFormSessionData] = useState(providedSessionData);
  const [formSessionDataError, setFormSessionDataError] = useState(null);
  const [formSessionDataLoading, setFormSessionDataLoading] = useState();
  const formIsTracking = formSessionData?.past24hrs > 0;
  const formTracked = form.tracked;
  const [formHasConfiguredFields, setFormHasConfiguredFields] = useState();

  // Fetch form session data for curr and prev
  useEffect(() => {
    if (formTracked && !providedSessionData?.hasOwnProperty('current')) (async () => {
      setFormSessionDataLoading(true);
      try {
        const {data: {count: {current, previous}}} = await api.get('/data/sessions/stats', {
          params: {
            formUuid: form.uuid,
            metric: 'count',
            timePeriod: {start: 'now-30d/d', end: 'now', previousStart:'now-60d/d', previousEnd: 'now-30d/d'},
          },
        });

        const calcPercentDiff = (curr, prev) => {
          const diff = ((curr - prev) / prev) * 100;
          return (Number.isNaN(diff) || diff === Infinity) ? null : diff;
        };
        setFormSessionData(prev => ({...prev, current, previous, percentDiff: calcPercentDiff(current, previous)}));
      } catch (e) {
        switch (e?.response?.status) {
        case 401:
          setFormSessionDataError('Not logged in');
          break;
        case 404:
          setFormSessionDataError('Form not found');
          break;
        default:
          setFormSessionDataError('Oops, something went wrong fetching form data. Please try again.');
        }
      } finally {
        setFormSessionDataLoading(false);
      }
    })();
    if (form && providedSessionData) setFormSessionData(providedSessionData);
  }, [form, providedSessionData, formTracked]);

  // Fetch form session data for past 24 hours
  useEffect(() => {
    if (formTracked && !providedSessionData?.hasOwnProperty('past24hrs')) (async () => {
      try {
        const {data: {count}} = await api.get('/data/sessions/stats', {
          params: {
            formUuid: form.uuid,
            metric: 'count',
            timePeriod: {start: 'now-24h', end: 'now'},
          },
        });

        setFormSessionData(prev => ({...prev, past24hrs: count}));
      } catch (e) {
        // Do nothing as this is only for a warning
      }
    })();
  }, [form, providedSessionData, formTracked]);

  useEffect(() => {
    if (formSessionData?.hasOwnProperty('current')) handleSessionCountFromForm({formUuid: form.uuid, currentCount: formSessionData.current});
  }, [form?.uuid, formSessionData, handleSessionCountFromForm]);

  // Find out if form has no configured fields
  useEffect(() => {
    if (formTracked && formSessionData?.past24hrs > 0) (async () => {
      try {
        const { data: { fields } } = await api.get('/fields', {
          params: {
            formUuid: form.uuid,
            labelled: true
          }
        });
        if (fields) setFormHasConfiguredFields(fields.length > 0);
      } catch (e) {
        // Do nothing as this is only for a warning
      }
    })();

  }, [formTracked, formSessionData, providedSessionData, form]);

  return (
    <SwitchTransition>
      <CSSTransition key={displayForms} timeout={400} classNames="dashboard-forms-fade" appear={true}>
        <div className={`form-summary`}>
          <Link to={`/insights?form[uuid]=${form.uuid}`}>
            <div className={`d-flex flex-column justify-content-between h-100`} data-testid={`view-form-${form.uuid}`}>
              <Row className="form-title-row g-0 justify-content-between">
                <Col className="p-0 col-1"></Col>
                <Col className="p-0 text-center col-10">
                  <h3>{form.label}</h3>
                </Col>
                {isFavForm && !showingOnFavFormsCard &&
                  <Col className="p-0 col-1" onClick={(e) => {
                    e.preventDefault(); // Prevent link to Form Data
                    handleRemoveFavForm({form});
                  }}><AiFillStar size="18px" className="fav-star" title="Remove from favourites" data-testid={`fav-form-star-${form.uuid}`}/>
                  </Col>}
                {!isFavForm && !showingOnFavFormsCard &&
                  <Col className="p-0 col-1" onClick={(e) => {
                    e.preventDefault(); // Prevent link to Form Data
                    handleSaveFavForm({form, formSessionData});
                  }}><AiOutlineStar size="18px" className="fav-star-outline" title="Save as a favourite form" data-testid={`fav-form-star-${form.uuid}`}/>
                  </Col>
                }
                {showingOnFavFormsCard &&
                  <Col className="p-0 col-1" onClick={(e) => {
                    e.preventDefault(); // Prevent link to Form Data
                    handleRemoveFavForm({form});
                  }}><VscChromeClose size="18px" className="fav-star-outline" title="Remove from favourites"/>
                  </Col>}
              </Row>
              <Row className="g-0 justify-content-between">
                {formSessionDataError && <Col className="p-0 d-flex align-items-center justify-content-center"><p className="mb-0 error-msg" data-testid="form-session-error">{formSessionDataError}</p></Col>}
                {!formSessionDataError && formSessionDataLoading &&
                  <Col className="d-flex justify-content-center"><FaSpinner size="18px" className="spinning-icon" title="Loading data..."/></Col>}
                {!formSessionDataError && !formSessionDataLoading && <>
                  <Col className="statContainer text-center align-self-center p-0">
                    <div>
                      {formTracked && formSessionData && <>
                        <p className="stats-current mb-1">{formTracked && formatNumber(formSessionData.current)}</p>
                        <div onClick={(e) => {e.preventDefault(); /* Prevent link to Form Data if just clicking prev stat info */}}>
                          <OverlayTrigger placement="right" trigger={['click', 'hover']}
                            overlay={
                              <Popover>
                                <Popover.Body>
                                  <p className="mb-0">
                                    {(formSessionData.current || formSessionData.current === 0) && (formSessionData.previous || formSessionData.previous === 0) &&
                                    `${formSessionData.previous.toLocaleString()} vs ${formSessionData.current.toLocaleString()}`}</p>
                                </Popover.Body>
                              </Popover>}>

                            <p className="stats-previous mb-0">
                              {formSessionData.previous !== 0 && <>
                                {formSessionData.current < formSessionData.previous ? <FaCaretDown size="18px" className="caret-down" /> : formSessionData.current > formSessionData.previous ? <FaCaretUp size="18px" className="caret-up" /> : null}
                                <u className="text-more-info">{(formSessionData.percentDiff || formSessionData.percentDiff === 0) && formSessionData.percentDiff.toLocaleString(undefined, {maximumFractionDigits: 2}) + '%'}</u>
                              </>}
                              {formSessionData.previous === 0 && formSessionData.previous}
                            </p>
                          </OverlayTrigger>
                        </div></>}
                      {!formTracked ?
                        <Alert variant="warning" className="tracking-warning-alert never-tracked mt-2">
                          <div className="pe-2 d-flex"><VscWarning size="14px"/></div>
                          <div  className="ps-1 pe-2">
                            <p className="alert-text m-0">Tracking not yet started.</p>
                            <p className="alert-text m-0">Review <Link to={`/forms/${form.uuid}/tracking_code`} target="_blank" onClick={(e) => {e.stopPropagation();}}>Tracking Code</Link></p>
                          </div>
                        </Alert> :
                        !formIsTracking ?
                          <Alert variant="warning" className="tracking-warning-alert mt-2">
                            <div className="pe-2 d-flex"><VscWarning size="14px"/></div>
                            <p className="alert-text m-0">No sessions tracked in past 24hrs</p>
                          </Alert> :
                          formHasConfiguredFields === false ?
                            <Alert variant="warning" className="tracking-warning-alert mt-2">
                              <div className="pe-2 d-flex"><VscWarning size="14px"/></div>
                              <div  className="ps-1 pe-2">
                                <p className="alert-text m-0">No fields configured yet.</p>
                                <p className="alert-text m-0">Go to <Link to={`/forms/${form.uuid}/fields`} target="_blank" onClick={(e) => {e.stopPropagation();}}>Label & Order Fields</Link></p>
                              </div>
                            </Alert>
                            : null
                      }
                    </div>
                  </Col>
                </>}
              </Row>
            </div>
          </Link>
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default DashboardFormCard;
