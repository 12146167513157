import React from 'react';
import { FaMobileAlt, FaDesktop, FaChrome } from 'react-icons/fa';
import { FaFirefoxBrowser, FaOpera, FaSafari } from "react-icons/fa6";
import { IoMdTabletPortrait } from "react-icons/io";
import { SiApple, SiAndroid, SiWindows, SiSamsung, SiLinux } from "react-icons/si";
import './AttributesIcons.scss';
import { Attributes } from '../../types/types';

const AttributesIcons = ({deviceType, browserFamily, operatingSystem, visitorType, trafficMedium, autofillTriggered}: Attributes
) => {
  return <div className="attributes-icons">
    <div>
      {deviceType === 'mobile' && <FaMobileAlt size="16px" className="m-1" title="mobile" />}
      {deviceType === 'desktop' && <FaDesktop size="16px" className="m-1" title="desktop" />}
      {deviceType === 'tablet' && <IoMdTabletPortrait size="16px" className="m-1" title="tablet" />}
      {browserFamily === 'Chrome' && <FaChrome size="16px" className="m-1" title="Chrome" />}
      {browserFamily === 'Firefox' && <FaFirefoxBrowser size="16px" className="m-1" title="Firefox" />}
      {browserFamily === 'Opera' && <FaOpera size="16px" className="m-1" title="Opera" />}
      {browserFamily === 'Safari' && <FaSafari size="16px" className="m-1" title="Safari" />}
      {browserFamily === 'Samsung Internet' && <SiSamsung size="25px" className="samsung-logo" title="Samsung Internet" />}
      {operatingSystem === 'Android' && <SiAndroid size="16px" className="m-1" title="Android" />}
      {operatingSystem === 'Mac OS X' && <SiApple size="16px" className="m-1" title="Mac OS X" />}
      {operatingSystem === 'Windows' && <SiWindows size="16px" className="m-1" title="Windows" />}
      {operatingSystem === 'Linux' && <SiLinux size="16px" className="m-1" title="Linux" />}
      {operatingSystem === 'iOS' && <SiApple size="16px" className="m-1" title="iOS" />}
    </div>
    <div>
      {visitorType && <div className="data-pair">
        <span className="badge bg-light text-dark fw-normal">
          <p className="mb-0"><span className="fw-500">Visitor Type:</span> {visitorType}</p>
        </span>
      </div>}
      {trafficMedium && trafficMedium !== 'none' && <div className="data-pair">
        <span className="badge bg-light text-dark fw-normal">
          <p className="mb-0"><span className="fw-500">trafficMedium:</span> {trafficMedium}</p>
        </span>
      </div>}
      {autofillTriggered && <div className="data-pair">
        <span className="badge bg-light text-dark fw-normal">
          <p className="mb-0"><span className="fw-500">autofillTriggered:</span> {autofillTriggered}</p>
        </span>
      </div>}
    </div>
  </div>;
};

export default AttributesIcons;
