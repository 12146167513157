import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import api from '../../api';
import qs from 'qs';
import { usePrevious } from '../../hooks';
import { labelForField, fixedAttributeFormatter } from '../../utils';
import { VscWarning } from 'react-icons/vsc';
import './SegmentSplit.scss';
import { zScoreOfProportions } from '../FieldData';
import SegmentSplitTable from './SegmentSplitTable';
import ProgressBar from 'react-bootstrap/ProgressBar';
import AppContext from '../../AppContext';

const ErrorMessage = () => <Row className="alert-row g-0 info-alert">
  <Alert variant="outline-danger" className="my-2">
    <div className="page-alert-svg-icon d-flex"><VscWarning size="100%"/></div>
    <p className="alert-text m-0 p-2">Sorry there was a problem loading this data.</p>
  </Alert>
</Row>;

const ProgressArea = ({progress}) => <div className="progress-area d-flex flex-grow-1">
  <div className="w-100 my-auto">
    <ProgressBar animated now={progress}/>
    <p>{progress >= 100 && 'Please wait, we are computing differences between segments...'}</p>
  </div>
</div>;

const SegmentSplit = ({submitFieldChangeFromSelect}) => {
  const {
    query,
  } = useContext(AppContext);
  const prevQuery = usePrevious(query);
  const location = useLocation();
  const { form: formInParams} = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [deviceTypeData, setDeviceTypeData] = useState();
  const [deviceTypeLoading, setDeviceTypeLoading] = useState(true);
  const [deviceTypeProgress, setDeviceTypeProgress] = useState(20);
  const [deviceTypeError, setDeviceTypeError] = useState();

  const [visitorTypeData, setVisitorTypeData] = useState();
  const [visitorTypeLoading, setVisitorTypeLoading] = useState(true);
  const [visitorTypeProgress, setVisitorTypeProgress] = useState(20);
  const [visitorTypeError, setVisitorTypeError] = useState();

  const [operatingSystemData, setOperatingSystemData] = useState();
  const [operatingSystemLoading, setOperatingSystemLoading] = useState(true);
  const [operatingSystemProgress, setOperatingSystemProgress] = useState(20);
  const [operatingSystemError, setOperatingSystemError] = useState();


  // Once a new query has been set - proceed to fetch data
  useEffect(() => {
    if (
      query?.form?.uuid && query?.time?.start && query?.time?.end && // Required
      (!formInParams || (formInParams && (query?.form?.uuid === formInParams.uuid))) && // Only fetch when query has been updated by params e.g. when coming from Dashboard
      (!prevQuery || (prevQuery && ( // Differences
        (prevQuery?.form?.uuid !== query?.form?.uuid) ||
        (JSON.stringify(prevQuery.filters) !== JSON.stringify(query.filters)) ||
        (!prevQuery.time || (prevQuery.time &&
          (JSON.stringify(prevQuery.time.start) !== JSON.stringify(query.time.start) ||
          (JSON.stringify(prevQuery.time.end) !== JSON.stringify(query.time.end)))))
      ))) &&
      !submitFieldChangeFromSelect // Don't reload all charts if only the submit field has changed
    ) {
      const { form, time, filters } = query;

      const loadAbandonedFieldsDataForAttribute = async (fixedAttribute) => {
        return await api.get('/visualisations/abandoned-fields', {
          params: {
            formUuid: form?.uuid,
            startTime: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            endTime: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            filter: fixedAttributeFormatter({filters: filters?.filter(f => f.key !== fixedAttribute.key), fixedAttribute}),
          },
        });
      };

      const loadFieldsInteractionDataForAttribute = async (fixedAttribute) => {
        return await api.get('/visualisations/return-to-field', {
          params: {
            formUuid: form?.uuid,
            startTime: time?.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            endTime: time?.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            filter: fixedAttributeFormatter({filters: filters.filter(f => f.key !== fixedAttribute.key), fixedAttribute}),
          },
        });
      };

      const abandonDataByIdentifier = ({data, sampleNum}) => data?.reduce((acc, item) => {
        const identifier = item.identifier;
        const { abandonCount, abandonRate, ...field } = item;

        acc[identifier] = {...field,
          [`p${sampleNum}AbandonRate`]: abandonRate,
          [`n${sampleNum}AbandonCount`]: abandonCount,
        };
        return acc;
      }, {});

      const interactionDataByIdentifier = ({data, sampleNum}) => data?.reduce((acc, item) => {
        const identifier = item.identifier;
        acc[identifier] = {
          [`i${sampleNum}InteractionCount`]: item.allSessionsTotal,
        };
        return acc;
      }, {});

      const getCombinedDataForAttribute = async ({attribute, values}) => {
        let abandonDataForSamples = {};
        let interactionDataForSamples = {};

        for await (const [i, v] of values.entries()) {
          const sampleNum = i + 1;

          let { data: abandonData } = await loadAbandonedFieldsDataForAttribute({key: attribute, label: v});
          abandonData = (abandonData || []).map(field => {
            field.label = field.fieldLabel;
            return {...field, label: labelForField(field)};
          }).filter(field => field.hasOwnProperty('hidden') ? !field.hidden : true);

          let { data: interactionData } = await loadFieldsInteractionDataForAttribute({key: attribute, label: v});
          interactionData = (interactionData || []).map(field => {
            field.label = field.fieldLabel;
            return {...field, label: labelForField(field)};
          }).filter(field => field.hasOwnProperty('hidden') ? !field.hidden : true);

          abandonDataForSamples[sampleNum] = abandonDataByIdentifier({data: abandonData || [], sampleNum});
          interactionDataForSamples[sampleNum] = interactionDataByIdentifier({data: interactionData || [], sampleNum});
        }

        const fieldIdentifiers = [...new Set([...Object.keys(abandonDataForSamples[1]), ...Object.keys(abandonDataForSamples[2])])];

        return fieldIdentifiers.map((identifier) => ({
          ...abandonDataForSamples[1]?.[identifier] && {...abandonDataForSamples[1][identifier]},
          ...abandonDataForSamples[2]?.[identifier] && {...abandonDataForSamples[2][identifier]},
          ...interactionDataForSamples[1]?.[identifier] && {...interactionDataForSamples[1][identifier]},
          ...interactionDataForSamples[2]?.[identifier] && {...interactionDataForSamples[2][identifier]},
        }));
      };

      const addSignificantDiffToData = ({data}) => data?.reduce((acc, field) => {
        const {
          p1AbandonRate = 0, p2AbandonRate = 0, n1AbandonCount = 0, n2AbandonCount = 0, i1InteractionCount = 0, i2InteractionCount = 0,
        } = field;
        const p1 = p1AbandonRate/100;
        const p2 = p2AbandonRate/100;
        const p = (n1AbandonCount + n2AbandonCount)/(i1InteractionCount + i2InteractionCount);
        const n1 = n1AbandonCount;
        const n2 = n2AbandonCount;
        const zScore = zScoreOfProportions({p1, p2, p, n1, n2});
        const significantDiff = zScore <= -1.65 || zScore >= 1.65;

        if (!significantDiff) return acc;

        acc.push({
          ...field,
          label: labelForField(field),
          zScore,
          differenceInAbandonRate: Math.abs(p1AbandonRate - p2AbandonRate),
        });

        return acc;
      }, []);

      // Load Device Type
      (async () => {
        try {
          setDeviceTypeLoading(true);
          setDeviceTypeProgress(20);
          setDeviceTypeData(null);
          setDeviceTypeError(null);

          const combinedData = await getCombinedDataForAttribute({attribute: 'deviceType', values: ['desktop', 'mobile']});

          if (!combinedData?.length) {
            setDeviceTypeData([]);  // Still set empty array so we can display the no data message
          } else {
            const dataWithDifferences = addSignificantDiffToData({data: combinedData})
              .sort((a,b) => b.differenceInAbandonRate - a.differenceInAbandonRate)
              .slice(0,3);

            setDeviceTypeData(dataWithDifferences);
          }
        } catch (e) {
          setDeviceTypeError(true);
        } finally {
          setDeviceTypeProgress(100);
          setDeviceTypeLoading(false);
        }
      })();

      // Load Visitor Type
      (async () => {
        try {
          setVisitorTypeLoading(true);
          setVisitorTypeProgress(20);
          setVisitorTypeData(null);
          setVisitorTypeError(null);

          const combinedData = await getCombinedDataForAttribute({attribute: 'Visitor Type', values: ['New', 'Returning']});

          if (!combinedData?.length) {
            setVisitorTypeData([]); // Still set empty array so we can display the no data message
          } else {
            const dataWithDifferences = addSignificantDiffToData({data: combinedData})
              .sort((a,b) => b.differenceInAbandonRate - a.differenceInAbandonRate)
              .slice(0,3);

            setVisitorTypeData(dataWithDifferences);
          }
        } catch (e) {
          setVisitorTypeError(true);
        } finally {
          setVisitorTypeLoading(false);
          setVisitorTypeProgress(100);
        }
      })();

      // Load Operating System
      (async () => {
        try {
          setOperatingSystemLoading(true);
          setOperatingSystemProgress(20);
          setOperatingSystemData(null);
          setOperatingSystemError(null);

          const combinedData = await getCombinedDataForAttribute({attribute: 'Operating System', values: ['iOS', 'Android']});

          if (!combinedData?.length) {
            setOperatingSystemData([]); // Still set empty array so we can display the no data message
          } else {
            const dataWithDifferences = addSignificantDiffToData({data: combinedData})
              .sort((a,b) => b.differenceInAbandonRate - a.differenceInAbandonRate)
              .slice(0,3);

            setOperatingSystemData(dataWithDifferences);
          }
        } catch (e) {
          setOperatingSystemError(true);
        } finally {
          setOperatingSystemLoading(false);
          setOperatingSystemProgress(100);
        }
      })();
    }
  }, [prevQuery, query, submitFieldChangeFromSelect, formInParams]);

  // Handle device type progress bar
  useEffect(() => {
    let interval;
    if (deviceTypeProgress < 100) {
      interval = setInterval(() => setDeviceTypeProgress((prevProgress) => prevProgress + 20), 400);
    }
    return () => clearInterval(interval);
  }, [deviceTypeProgress]);

  // Reset device type progress bar
  useEffect(() => {
    if (deviceTypeData) setDeviceTypeProgress(0);
  }, [deviceTypeData]);

  // Handle visitor type progress bar
  useEffect(() => {
    let interval;
    if (visitorTypeProgress < 100) {
      interval = setInterval(() => setVisitorTypeProgress((prevProgress) => prevProgress + 20), 400);
    }
    return () => clearInterval(interval);
  }, [visitorTypeProgress]);

  // Reset visitor type progress bar
  useEffect(() => {
    if (visitorTypeData) setVisitorTypeProgress(0);
  }, [visitorTypeData]);

  // Handle operating system progress bar
  useEffect(() => {
    let interval;
    if (operatingSystemProgress < 100) {
      interval = setInterval(() => setOperatingSystemProgress((prevProgress) => prevProgress + 20), 400);
    }
    return () => clearInterval(interval);
  }, [operatingSystemProgress]);

  // Reset operating system progress bar
  useEffect(() => {
    if (operatingSystemData) setOperatingSystemProgress(0);
  }, [operatingSystemData]);

  return (
    <Row className="g-0 mb-4">
      <Card className="w-100" id="segment-split">
        <Card.Body className="d-flex flex-column">
          <Row className="g-0 card-title-row">
            <Col className="p-0">
              <Card.Title as="h3">Key Segment Differences</Card.Title>
            </Col>
          </Row>
          <Row className="g-0">
            <Col className="p-0 col-auto">
              <h4 className="card-tagline mb-0">The three fields with the largest <OverlayTrigger placement="top" overlay={<Popover id="html-attributes-popover">
                <Popover.Body className="text-start">
                  Statistical significance is calculated at the 95% level.
                </Popover.Body>
              </Popover>}>
                <span className="text-more-info"><i>statistically significant</i></span>
              </OverlayTrigger> difference in abandonment rate for certain audience segmentations.</h4>
              <p className="mb-0">(Fields are only included if a significant difference exists so there may be less than three fields in some categories).</p>
            </Col>
          </Row>
          <div className="d-grid segment-split-card-grid py-3">
            <Card className="segment-split-inner-card">
              <Card.Body className="d-flex flex-column">
                <Row className="g-0 card-title-row">
                  <Col className="p-0 justify-content-center text-center">
                    <Card.Title as="h3">Device Type</Card.Title>
                  </Col>
                </Row>
                {deviceTypeData ?
                  <SegmentSplitTable data={deviceTypeData} headerLabels={['Desktop', 'Mobile']} />
                  : deviceTypeError ?
                    <ErrorMessage />
                    : deviceTypeLoading &&
                    <ProgressArea progress={deviceTypeProgress} />
                }
              </Card.Body>
            </Card>
            <Card className="segment-split-inner-card row-span-2">
              <Card.Body className="d-flex flex-column">
                <Row className="g-0 card-title-row">
                  <Col className="p-0 justify-content-center text-center">
                    <Card.Title as="h3">New Vs Returning</Card.Title>
                  </Col>
                </Row>
                {visitorTypeData ?
                  <SegmentSplitTable data={visitorTypeData} headerLabels={['New', 'Returning']} />
                  : visitorTypeError ?
                    <ErrorMessage />
                    : visitorTypeLoading &&
                      <ProgressArea progress={visitorTypeProgress} />
                }
              </Card.Body>
            </Card>
            <Card className="segment-split-inner-card">
              <Card.Body className="d-flex flex-column">
                <Row className="g-0 card-title-row">
                  <Col className="p-0 justify-content-center text-center">
                    <Card.Title as="h3">Operating System</Card.Title>
                  </Col>
                </Row>
                {operatingSystemData ?
                  <SegmentSplitTable data={operatingSystemData} headerLabels={['iOS', 'Android']} />
                  : operatingSystemError ?
                    <ErrorMessage />
                    : operatingSystemLoading &&
                    <ProgressArea progress={operatingSystemProgress} />
                }
              </Card.Body>
            </Card>
          </div>
          <p className="mb-0">This data reveals the fields that particular segments abandon at a higher rate than others. To dig deeper into field level data by audience use
            the <Link to={`/field-segment-comparison`} target="_blank">Field Segment Comparison</Link> report.</p>
        </Card.Body>
      </Card>
    </Row>);

};

export default SegmentSplit;