import React from 'react';
import Select, { StylesConfig, OptionProps, CSSObjectWithLabel, GroupBase } from 'react-select';
import { Field, LoadingOptionType, SelectFieldOptionType, FieldOptionValueType } from '../../types/types';

const FieldSelect = ({
  field, availableFields, handleChange, id = 'field-select', placeholder = 'Select a field...', isMulti = false,
  isClearable = false, fieldsLoading, fieldsLoadingError, maxSelected = 50,
}) => {
  type IsMulti = boolean;

  const selectStyles: StylesConfig<LoadingOptionType | FieldOptionValueType, IsMulti> = {
    control: (styles, state) => {
      return {...styles,
        border: (id ===  'metric-field-select' && state.selectProps.value) ? '1px solid #6b79e1' : '1px solid #EBEDF2',
        boxShadow: '',
      };
    },
    option: (styles: CSSObjectWithLabel, state: OptionProps<LoadingOptionType | FieldOptionValueType, boolean, GroupBase<LoadingOptionType | FieldOptionValueType>>) => {
      const stateWithValue: (OptionProps & SelectFieldOptionType) = state as (OptionProps & SelectFieldOptionType);
      const field = state.selectProps.value as unknown as Field;
      return ({...styles,
        color: state.isDisabled ? '#9e9e9e' : '#3f4047',
        backgroundColor: state.selectProps.value && (field.label === stateWithValue.value) ? "#E2E5Ec" : undefined,
        '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : undefined}
      });
    },
    menu: (styles, state) => ({...styles,
      marginTop: '1px',
      borderRadius: '4px',
      border: '1px solid #EBEDF2',
      boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
    }),
    dropdownIndicator: (styles, state) => {
      return {
        ...styles,
        cursor: 'pointer',
        padding: '6px 8px',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
        transition: 'transform .5s ease',
      };
    },
    multiValue: (provided, state) => ({...provided,
      background:'#ebedf2',
    }),
    multiValueLabel: (provided, state) => ({...provided,
      color: '#575962',
      fontSize: '1rem',
    }),
    valueContainer: (styles, state) => ({...styles,
      maxHeight: '75px',
      overflow: 'scroll',
      paddingTop: '0px',
      paddingBottom: '0px',
      '&::-webkit-scrollbar': {
        WebkitAppearance: 'none',
        width: '5px',
        height: '1px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '5px',
        backgroundColor: 'rgba(0, 0, 0, .5)',
      },
    }),
  };

  return (
    <Select
      id={id}
      isMulti={isMulti}
      styles={selectStyles}
      options={fieldsLoading ? [{ selectable: false, value: null, label: <><i className="fa fa-circle-o-notch fa-spin fa-fw" /> Loading...</>}] :
        fieldsLoadingError ? [] : availableFields && availableFields.length > 0 ? availableFields.map((field) =>
          ({...field, ...field.identifier && {value: field.identifier}})) : []}
      onChange={handleChange}
      placeholder={placeholder}
      isClearable={isClearable}
      isLoading={fieldsLoading}
      value={field}
      isOptionDisabled={option => (isMulti && field?.length >= maxSelected) || (option.hasOwnProperty('selectable') && !option.selectable)}
    />
  );
};

export default FieldSelect;


