import React from 'react';
import { SelectOptionType } from '../../types/types';
import './ListBox.scss';

const ListBox = ({
  value, options, handleChange, id, withScroll,
}: {
  value: string | undefined,
  options: SelectOptionType[],
  handleChange: (event) => void,
  id?: string,
  withScroll?: boolean,
}) => {

  return (
    <div className={`listbox-wrapper ${withScroll ? 'with-scroll' : ''}`}>
      <ul className="listbox mb-0" id={id} role="listbox" tabIndex={0} aria-label="listbox" onClick={handleChange}>
        {options.map(o => <li key={o.value}
          className={`list-item ${value === o.value ? 'selected' : ''}`}
          id={o.value}
          role="option"
          aria-selected={value === o.value}
        >{o.label}</li>)}
      </ul>
    </div>
  );
};

export default ListBox;
