import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { MetricRuleType } from '../../types/sessions';
import { Time, Form, Filter, FilterForSelect,
} from '../../types/types';

import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

import MetricsRuleRow from './MetricsRuleRow';
import AppContext from '../../AppContext.js';
import {
  compileQueryString,
  orgDetailsForMixpanel,
} from '../../utils.js';

const MetricsTable = ({mixpanel, availableTrackedFields, page}) => {
  const history = useHistory();
  const {
    query
  } = useContext(AppContext);

  const {
    time,
    form,
    filters,
    sessionOutcomes,
    sessionFilters,
    sessionExplorer,
  }: {
    time: Time,
    form: Form,
    filters: FilterForSelect[],
    sessionOutcomes: Filter[],
    sessionFilters: {
      abandonedFieldIdentifier: string,
      interactedWithFieldIdentifiers: string[],
      metrics: MetricRuleType[],
      customEventTypes: string[],
    },
    sessionExplorer?: {
      includeCustomEvents: boolean,
    }
  } = query || {};

  const {
    abandonedFieldIdentifier,
    interactedWithFieldIdentifiers,
    metrics,
    customEventTypes,
  } = sessionFilters || {};

  const [showInputRow, setShowInputRow] = useState(false);

  const handleAddMetrics = useCallback(({metricRule}: {metricRule: MetricRuleType | null}) => {
    mixpanel.track('Selected add metrics', { page: 'SessionReplay', ...orgDetailsForMixpanel(form.organisation) });
    if (!metricRule) return;
    const newMetrics = (metrics || []).concat(metricRule);

    history.push(compileQueryString({
      form,
      time,
      filters,
      sessionOutcomes,
      ...page === 'SessionExplorer' && {sessionExplorer},
      sessionFilters: {
        interactedWithFieldIdentifiers,
        abandonedFieldIdentifier,
        metrics: newMetrics,
        customEventTypes,
      }
    }));
  }, [form, filters, history, mixpanel, sessionOutcomes, time, metrics, interactedWithFieldIdentifiers, abandonedFieldIdentifier, customEventTypes, sessionExplorer, page]);

  const handleRemoveMetric = useCallback((index: number) => {
    mixpanel.track('Selected remove metrics', { page: 'SessionReplay', ...orgDetailsForMixpanel(form.organisation) });
    const newMetrics = metrics;
    newMetrics.splice(index, 1);

    history.push(compileQueryString({
      form,
      time,
      filters,
      sessionOutcomes,
      ...page === 'SessionExplorer' && {sessionExplorer},
      sessionFilters: {
        interactedWithFieldIdentifiers,
        abandonedFieldIdentifier,
        metrics: newMetrics,
        customEventTypes,
      }
    }));
  }, [form, filters, history, mixpanel, sessionOutcomes, time, metrics, interactedWithFieldIdentifiers, abandonedFieldIdentifier, customEventTypes, sessionExplorer, page]);

  const handleEditMetric = useCallback(({index, metricRule}: {index: number, metricRule: MetricRuleType | null}) => {
    mixpanel.track('Selected edit metrics', { page: 'SessionReplay', ...orgDetailsForMixpanel(form.organisation) });
    if (!metricRule) return;
    const newMetrics = [...metrics];
    newMetrics[index] = metricRule;

    history.push(compileQueryString({
      form,
      time,
      filters,
      sessionOutcomes,
      ...page === 'SessionExplorer' && {sessionExplorer},
      sessionFilters: {
        interactedWithFieldIdentifiers,
        abandonedFieldIdentifier,
        metrics: newMetrics,
        customEventTypes,
      }
    }));
  }, [form, filters, history, mixpanel, sessionOutcomes, time, metrics, interactedWithFieldIdentifiers, abandonedFieldIdentifier, customEventTypes, sessionExplorer, page]);

  useEffect(() => {
    if (!metrics) {
      setShowInputRow(true);
    } else {
      setShowInputRow(false);
    }
  }, [metrics]);

  return (
    <Table id="replay-metrics-table">
      <thead>
        <tr>
          <th>Metric</th>
          <th>For</th>
          <th>Rule</th>
          <th>Value</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {metrics && metrics.length > 0 && metrics.map((metric, i) => (
          <MetricsRuleRow
            key={`metric-${metric.type}-${metric.for}-${i}`}
            appliedMetric={metric}
            handleRemoveMetric={handleRemoveMetric}
            handleEditMetric={handleEditMetric}
            availableTrackedFields={availableTrackedFields}
            index={i}
          />
        ))}
        {showInputRow &&
          <MetricsRuleRow
            key={`metric-row-show`}
            handleAddMetrics={handleAddMetrics}
            availableTrackedFields={availableTrackedFields}
          />}
        {!showInputRow &&
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <Button variant="outline-secondary" className="mx-0 px-3 py-2 add-metric-cta text-nowrap"
              onClick={() => setShowInputRow(true)}>Add Rule</Button>
          </td>
        </tr>}
      </tbody>
    </Table>
  );
};

export default MetricsTable;
