import React, { useEffect, useContext, useCallback, useState } from 'react';
import { useHistory, useLocation, useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import qs from 'qs';
import { VscCheck, VscInfo } from "react-icons/vsc";
import { AiOutlineVideoCamera } from 'react-icons/ai';

import AppContext from '../AppContext';
import AppAlerts from '../Components/AppAlerts';
import FeedbackRow from '../Components/FeedbackRow';
import api from '../api';
import { orgDetailsForMixpanel } from '../utils';
import NavBar from '../NavBar';
import DashboardOrgCard from './DashboardOrgCard';
import DashboardFavsCard from './DashboardFavsCard';
import { HiExternalLink } from 'react-icons/hi';
import './Dashboard.scss';

export const sortOptions = [
  {label: 'Most recently added', value: 'newest-first'},
  {label: 'Total sessions', value: 'total-sessions'},
  {label: 'A - Z', value: 'a-z'}
];

export const sortDashboardCards = ({parentForms, sortedOn, formsSessionCount}) => {
  switch (sortedOn.value) {
  case 'newest-first':
    return parentForms.sort((a,b) => a.createdAt.localeCompare(b.createdAt));
  case 'total-sessions':
    return parentForms.sort((a,b) => formsSessionCount?.[b.uuid]?.currentCount - formsSessionCount?.[a.uuid]?.currentCount);
  case 'a-z':
    return parentForms.sort((a,b) => a.label.localeCompare(b.label));
  default:
    return parentForms;
  }
};

const Dashboard = ({mixpanel}) => {
  const history = useHistory();
  const location = useLocation();
  const {uuid: userUuid} = useParams();
  const { currentUser, setUser } = useContext(AppContext);

  const [differentUser, setDifferentUser] = useState(null);
  const [differentUserError, setDifferentUserError] = useState(null);
  const [orgsLoading, setOrgsLoading] = useState(true);
  const [orgsError, setOrgsError] = useState(null);
  const [orgs, setOrgs] = useState(null);
  const [favForms, setFavForms] = useState();
  const [favFormsSessionData, setFavFormsSessionData] = useState();
  const [favFormsError, setFavFormsError] = useState();

  const { checkout_success } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [showCheckoutSuccessMsg, setShowCheckoutSuccessMsg] = useState(checkout_success);

  const [showSignUpSuccessMsg, setShowSignUpSuccessMsg] = useState(location.state?.signUpSuccess);
  const firstOrgWithReplay = orgs?.find(o => o.plan?.includes('replay'));
  const firstOrg = useCallback(() =>
    currentUser.organisations && (currentUser.organisations.find(o => (o.contractType === 'monthly' || o.contractType === 'fixed')) || currentUser.organisations.find(o => o.contractType === 'trial')),
  [currentUser.organisations]);

  const handleSaveFavForm = useCallback(async ({form, formSessionData}) => {
    try {
      setFavFormsError(null);
      await api.post(`users/${userUuid || currentUser.uuid}/forms/${form.uuid}/favourite`);
      setFavForms(prev => (prev || []).concat(form));
      setFavFormsSessionData(prev => ({...prev, [form.uuid]: {...formSessionData}}));
      if (!differentUser) setUser(prev => ({...prev, favouriteForms: (prev.favouriteForms || []).concat(form)}));
      mixpanel.track('Added Favourite Form', { page: 'Dashboard', ...orgDetailsForMixpanel(firstOrg)});
    } catch (e) {
      switch (e?.response?.status) {
      case 404:
        setFavFormsError('User or Form not found');
        break;
      default:
        setFavFormsError('Oops, something went wrong saving the favourite form. Please try again.');
      }
    }

  }, [currentUser.uuid, userUuid, setUser, differentUser, mixpanel, firstOrg]);

  const handleRemoveFavForm = useCallback(async ({form}) => {
    try {
      setFavFormsError(null);
      await api.delete(`users/${userUuid || currentUser.uuid}/forms/${form.uuid}/favourite`);
      setFavForms(prev => prev.filter(({uuid}) => uuid !== form.uuid));
      if (!differentUser) setUser(prev => ({...prev, favouriteForms: prev.favouriteForms.filter(({uuid}) => uuid !== form.uuid)}));
      mixpanel.track('Removed Favourite Form', { page: 'Dashboard',  ...orgDetailsForMixpanel(firstOrg)});
    } catch (e) {
      switch (e?.response?.status) {
      case 404:
        setFavFormsError('User or Form not found');
        break;
      default:
        setFavFormsError('Oops, something went wrong removing the favourite form. Please try again.');
      }
    }
  }, [currentUser.uuid, userUuid, setUser, differentUser, mixpanel, firstOrg]);

  // Refetch current user after a successful checkout to make sure their orgs are up to date
  useEffect(() => {
    if (showCheckoutSuccessMsg) {
      (async () => {
        try {
          const { data: { organisations } } = await api.get(`/users/${currentUser.uuid}/organisations`, {
            params: {
              include: ['forms'],
            }
          });
          setUser(prev => ({...prev, organisations}));
          mixpanel.track('Successful checkout confirmed', { page: 'Dashboard', ...orgDetailsForMixpanel(
            organisations.find(o => (o.contractType === 'monthly' || o.contractType === 'fixed')) || organisations.find(o => o.contractType === 'trial'))
          });
        } catch (e) { /* Do nothing as this is a background update */ }
      })();
    }
  }, [showCheckoutSuccessMsg, setUser, currentUser.uuid, mixpanel]);


  useEffect(() => {
    mixpanel.identify(currentUser.email);
    mixpanel.track('Page View', { page: 'Dashboard',  ...orgDetailsForMixpanel(firstOrg)});
  }, [mixpanel, currentUser.email, currentUser.organisations, firstOrg]);

  // Simply don't allow a normal user to access another user's dashboard
  useEffect(() => {
    if (!currentUser.accountManager && userUuid) history.replace('/');
  }, [currentUser.accountManager, userUuid, history]);

  // Fetch full org details
  useEffect(() => {
    const fetchFullOrgs = async () => {
      try {
        const { data: { organisations } } = await api.get(`/users/${userUuid || currentUser.uuid}/organisations`, {
          params: {
            include: ['forms'],
          }
        });

        setOrgs(organisations);
        setOrgsLoading(false);
      } catch (e) {
        if (e.response && e.response.status === 403) {
          history.replace('/');
        } else {
          setOrgsError((e.response && (e.response.status === 404)) ? 'User not found.' :
            (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Oops, something went wrong loading organisations. Please try again.');
        }
      } finally {
        setOrgsLoading(false);
      }
    };

    if (currentUser.accountManager || !userUuid || (userUuid && (userUuid === currentUser.uuid))) fetchFullOrgs();
  }, [currentUser.accountManager, currentUser.uuid, userUuid, history]);

  // For a different user than the currentUser, i.e. likely requested by an account manager - fetch their details
  useEffect(() => {
    if (userUuid && (userUuid !== currentUser.uuid) && currentUser.accountManager) {
      const fetchDifferentUser = async () => {
        try {
          const { data: { user } } = await api.get(`/users/${userUuid}`);

          setDifferentUser(user);
        } catch (e) {
          if (e.response && e.response.status === 403) {
            history.replace('/');
          } else {
            setDifferentUserError('Oops, something went wrong loading a different user.');
          }
        }
      };
      fetchDifferentUser();
    } else {
      setDifferentUser(null); // Account maanger likely removed userUuid
    }
  }, [currentUser, userUuid,  history]);

  useEffect(() => {
    if (userUuid && (userUuid !== currentUser.uuid) && currentUser.accountManager) {
      setFavForms(differentUser?.favouriteForms);
    } else {
      setFavForms(currentUser.favouriteForms);
    }
  }, [userUuid, currentUser, differentUser]);

  return (
    <Container fluid className="dashboard page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Dashboard</title>
      </Helmet>
      <div className="nav-wrapper">
        <NavBar mixpanel={mixpanel}/>
      </div>
      <div className="main-content">
        <Col className="center-column justify-content-md-center">
          <FeedbackRow
            classList={['allow-scroll-under-nav']}
            mixpanel={mixpanel}
            page={'Dashboard'}
            org={null}
            messageContent={'your Dashboard'} />
          <AppAlerts />
          <Row className="title-row g-0">
            <Col className="p-0">
              <h1 id="dashboard-title" data-testid="page-title">
                Dashboard {differentUser && `for user ${differentUser.email}`}
              </h1>
            </Col>
          </Row>
          {showCheckoutSuccessMsg && <>
            <Row className="alert-row g-0 mb-3">
              <Alert dismissible variant="success" closeVariant="white"
                onClose={() => setShowCheckoutSuccessMsg(false)}>
                <div className="alert-svg-icon my-auto"><VscCheck size="100%"/></div>
                <p className="alert-text m-0">Thank you. Your payment has been successful and your upgrade has been applied.</p>
              </Alert>
            </Row>
            {firstOrgWithReplay && firstOrgWithReplay.forms.length > 0 &&
              <Row className="alert-row g-0 mb-3">
                <Alert variant="info" closeVariant="white">
                  <div className="alert-svg-icon my-auto d-flex"><VscInfo size="20px" className="me-2"/></div>
                  <p className="alert-text m-0">Please review the code installed on your forms to make sure that the <i>Session Replay</i> code is included. Go to Form settings &gt; Tracking Code. For example, check your first form's <Link to={`/forms/${firstOrgWithReplay.forms[0].uuid}/tracking_code`}>Tracking Code</Link>.</p>
                </Alert>
              </Row>}
          </>}
          {showSignUpSuccessMsg &&
            <Row className="alert-row g-0 mb-3">
              <Alert dismissible variant="success" closeVariant="white"
                onClose={() => setShowSignUpSuccessMsg(false)}>
                <div className="alert-svg-icon my-auto"><VscCheck size="100%"/></div>
                <p className="alert-text m-0">Sign up complete. Welcome to your free trial.</p>
              </Alert>
            </Row>}
          <Row className="alert-row g-0 mb-3">
            <Alert variant="info" closeVariant="white">
              <div className="alert-svg-icon my-auto d-flex"><VscInfo size="20px" className="me-2"/></div>
              <p className="alert-text m-0">Look out for the <AiOutlineVideoCamera size="20px" className="mx-1 icon-in-p"/> links
              to <i className="ms-1">Session Replay</i>. With replay setup on your forms, these links will launch a custom list of session recordings. <a target="_blank"
                rel="noopener noreferrer" href="https://www.zuko.io/session-replay" className="ms-1">Find out more<HiExternalLink className="align-top" /></a></p>
            </Alert>
          </Row>
          {orgsError && <p data-testid="orgs-fetch-error">{orgsError}</p>}
          {!orgsError && !orgsLoading && differentUserError && <p>{differentUserError}</p>}
          {!orgsError && !orgsLoading && (orgs && orgs.length === 0) && <p>This user is not a member of any organisations.</p>}
          {orgs?.length > 0 && orgs.some(o => o.forms.length > 1) &&
          <DashboardFavsCard
            favForms={favForms}
            favFormsSessionData={favFormsSessionData}
            handleRemoveFavForm={handleRemoveFavForm}
            favFormsError={favFormsError}
            mixpanel={mixpanel}
            firstOrg={firstOrg}
          />}
          {orgs?.length > 0 && orgs.map((org) => (
            <DashboardOrgCard
              org={org}
              key={org.uuid}
              mixpanel={mixpanel}
              handleSaveFavForm={handleSaveFavForm}
              handleRemoveFavForm={handleRemoveFavForm}
              favFormUuids={favForms?.map(({uuid}) => uuid)}
              firstOrg={firstOrg}
            />
          ))}
        </Col>
      </div>
    </Container>
  );
};

export default Dashboard;
