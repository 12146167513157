import React, { useMemo } from 'react';
import { useTable, useSortBy, usePagination, useFlexLayout, Column } from 'react-table';
import { Link } from 'react-router-dom';
import FormElement from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import {
  formatCellToSeconds,
  formatCellToTime,
} from '../../utils.js';

import { Form } from '../../types/types';
import { ReplaySession } from '../../types/sessions';

import './SessionsTable.scss';
import AttributesIcons from './AttributesIcons';

const SessionsTable = ({
  data,
  form,
}: {
  data: ReplaySession[],
  form: Form,
}) => {
  const columns = useMemo<Column<ReplaySession>[]>(() => [
    {Header: 'Start Time', accessor: 'startedAt', Cell: formatCellToTime, width: 200},
    {Header: 'Outcome', accessor: 'outcome', width: 120},
    {Header: 'Default Attributes', accessor: 'attributes', width: 150,  disableSortBy: true},
    {Header: 'Active Duration', accessor: 'activeDuration', Cell: formatCellToSeconds, width: 120},
    {Header: 'Session Field Returns', accessor: 'rtfCount'},
    {Header: 'Replays', accessor: 'replayIds'},
  ], []);
  const pageSize = 10;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    state,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    initialState: {
      pageSize,
      sortBy: [{ id: 'startedAt', desc: true }],
    },
    autoResetHiddenColumns: false, // Retains hidden columns on data changes
    autoResetSortBy: false,  // Retains sort on data changes
  },
  useFlexLayout,
  useSortBy,
  usePagination,
  );

  return (
    <div id="replay-sessions-table">
      <div className="table-wrap mb-3 ms-2">
        <Table className="datatable mb-0" hover {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr className="table-header-row" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  return (
                    <th className={`header-row-${column.id} d-flex justify-content-center align-items-center`}
                      {...column.getHeaderProps(column.getSortByToggleProps())} title="Sort up/down"><div className="header-text-with-sort">{column.render('Header')}</div>
                      <div className="table-sort-icons d-flex flex-column justify-content-center">
                        {column.canSort && <>
                          <FaSortUp className={`sort-up ${(column.isSorted && !column.isSortedDesc) ? 'sorted' : ''}`} size="16px"/>
                          <FaSortDown className={`sort-down ${(column.isSorted && column.isSortedDesc) ? 'sorted' : ''}`} size="16px"/>
                        </>}
                      </div>
                    </th>);
                })}
              </tr>
            ))}
          </thead>
          <tbody className="table-body" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    switch (cell.column.id) {
                    case 'replayIds':
                      return <td className={`cell-header-${cell.column.id}`} {...cell.getCellProps()}>{cell.value.map(replayId => <div key={replayId} className="py-1"><Link
                        to={{
                          pathname: `/session-replay/${replayId}`,
                          state: {form, session: row.original}
                        }}>
                        <Button className="view-replay-cta text-nowrap">View Replay</Button>
                      </Link></div>)}</td>;
                    case 'attributes':
                      return <td className={`cell-header-${cell.column.id}`} {...cell.getCellProps()}>
                        <AttributesIcons
                          deviceType={cell.value.deviceType}
                          browserFamily={cell.value.browserFamily}
                          operatingSystem={cell.value['Operating System']}
                          visitorType={cell.value['Visitor Type']}
                        />
                      </td>;
                    case 'outcome':
                      return <td className={`text-capitalize cell-header-${cell.column.id}`} {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    default:
                      return <td className={`cell-header-${cell.column.id}`} {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {rows.length > 0 &&
          <div className="d-flex justify-content-between align-items-center paginate-info-row">
            <FormGroup className="d-flex align-items-center" controlId="show-rows">
              <p className="mb-0 text-nowrap">Sessions per page:</p>
              <FormElement.Select value={state.pageSize} aria-label="Submissions per page" onChange={e => setPageSize(Number(e.target.value))} className="ms-2">
                {[10, 20, 30].map((size) => (<option key={size} value={size}>{size}</option>))}
              </FormElement.Select>
            </FormGroup>
            <div className="pagination p-2 justify-content-center">
              {rows?.length > pageSize && <>
                <div className={`page-item ${!canPreviousPage ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</button>
                </div>
                <div className={`page-item ${!canNextPage ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => nextPage()} disabled={!canNextPage}>Next</button>
                </div>
              </>}
            </div>
            <div>
              <p className="mb-0">Showing page {state.pageIndex +1} of {pageCount} pages</p>
            </div>
          </div>}
    </div>
  );
};

export default React.memo(SessionsTable);
